/* You can add global styles to this file, and also import other style files */
@import "~@ng-select/ng-select/themes/default.theme.css";

html,
body {
  margin: 0px;
  height: 100%;
  width: 100%;
}

app-root {
  display: flex;
  height: 100%;
  width: 100%;
}

.fs-075 {
  font-size: 0.75rem;
  line-height: 1rem;
}

.row-hover .row-data:hover {
  color: var(--bs-primary) !important;
}

th {
  font-size: 12px;
  font-weight: 500;
  color: #5e6a74;
}

td {
  color: #4a5560;
  padding-top: 17px;
  padding-bottom: 17px;
}

@media (max-width: 575.98px) {
  .custom-footer {
    font-size: 0.8rem;
  }
}

.card {
  border: none;
  border-radius: 1rem;
}
